import { assert } from '@/helpers'

const memberCardTypeComponents = {
  fundraising: 'MemberCardRenewalFR',
  'leadership-circle': 'MemberCardRenewalLC'
}
export default class CRHeaderContentAdapter {
  constructor (cmsData) {
    this.cmsData = cmsData
  }

  adapt () {
    const model = new CRHeaderContentModel()
    model.heading = this.cmsData.heading
    model.content = this.cmsData.body
    model.memberCard = this.cmsData['member-card']
    model.memberCardType = this.cmsData['member-card-type']

    if (model.memberCard) {
      model.memberCardTypeComponent = memberCardTypeComponents[model.memberCard.type.toLowerCase()]
      model.pending = model.memberCard.pending || false

      return model
    } else if (model.memberCardType) {
      model.memberCardTypeComponent = memberCardTypeComponents[model.memberCardType.toLowerCase()]
      model.pending = false

      assert(model.memberCardTypeComponent, 'unsupported member card type: ' + model.memberCardType)
    }

    return model
  }
}

export class CRHeaderContentModel {
  heading
  content
  pending
  memberCard
  memberCardType
  memberCardTypeComponent
}
